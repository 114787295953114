body {
  background-color: rgb(255,255,255);  
  margin: 0 !important;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.image {
  width: 100px;
  margin-top: 10px;
}

@media (max-width: 770px) {
  .container {
    margin: 0 10%;
  }
  .image {
    width: 100px;
  }
}

.home {
  background: url(./images/1a.jpg) no-repeat;
    /* background-size: cover; */
    background-position: center;
    min-height: 300px;
   max-height: 400px;
   width: 100%;
}

.main {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}