.container {
    margin: 30px 0;
}

.card {
    margin: 0 2% !important;
    width: 20vw;
}

.infected {
    border-bottom: 10px solid rgba(0, 0, 255, 0.5);
}
.recovered {
    border-bottom: 10px solid rgba(0, 255, 0, 0.5);
}
.deaths {
    border-bottom: 10px solid rgba(255, 0, 0, 0.5);
}

@media (max-width: 770px) {
    .card {
        margin: 2% 0 !important;
    }
}


.divider{
    color: #fff;
    text-align: center;
  }
  
  .dividerOne {
    background: #c58000;
  }
  
  .dividerTwo {
    background: #009933;
  }
  .dividerThree {
    background: #a00b01;
  }
  
  .divider .fa {
    border: 2px solid #fff;
    border-radius: 50%;
    font-size: 32px;
    width: 80px;
    height: 80px;
    line-height: 76px;
    text-transform: capitalize;
  }
  
  .cardHeader {
    padding-bottom: 4px;
  }
  
  .cardDesc {
    margin: 0 0 10px;
  }